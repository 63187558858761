///imports this is the create page///
import React, { useEffect, useState, useRef, useMemo } from "react";
import { db, auth } from "../config/firebase";
import { getDocs, collection, addDoc, Timestamp } from "firebase/firestore";
import ".//about.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import useOnclickOutside from "react-cool-onclickoutside";
//import Places from "./mapview"; // Import your API key

const libraries = ["places"];
const callbackapi = ["About"];

export default function Placed() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"]
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Initmap />;
}

function Initmap() { 
  
  //loadScript("https://maps.googleapis.com/maps/api/js?key=process.env.REACT_APP_GOOGLE_MAPS_API_KEY&libraries=places&callback=Initmap");
  const [eventList, setEventList] = useState([]);
  const textContainerRef = useRef(null);
  //const { selectedAddress } = useMapContext();

  // New Event States
  const [newEventCity, setNewEventCity] = useState("");
  const [newEventEndTime, setNewEventEndTime] = useState(null);
  const [newEventName, setNewEventName] = useState("");
  const [newEventLink, setNewEventLink] = useState("");
  const [newEventLocation, setNewEventLocation] = useState("");
  const [newEventPrice, setNewEventPrice] = useState(0);
  const [newEventStartTime, setNewEventStartTime] = useState(null);
  const [newEventType, setNewEventType] = useState("");
  const [newEventVenue, setNewEventVenue] = useState("");

  // Update Name State
  const [updatedName, setUpdatedName] = useState("");

  
  const eventsCollectionRef = collection(db, "events");

  const getEventList = async () => {
    try {
      const data = await getDocs(eventsCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setEventList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmitEvent = async () => {
    try {
      // Check if any required fields are empty
      if (
        !newEventName ||
        !newEventCity ||
        !newEventType ||
        !newEventVenue ||
        !newEventStartTime ||
        !newEventEndTime ||
        !newEventPrice ||
        !newEventLocation ||
        !newEventLink
      ) {
        alert("Please fill out all required fields before submitting.");
        return; // Don't submit the form if any required fields are empty
      }

      // Convert newEventStartTime to Firestore Timestamp
      const startTimeTimestamp = Timestamp.fromDate(
        new Date(newEventStartTime)
      );

      // Convert newEventPrice to a number (assuming it's a string with numeric characters)
      const priceAsNumber = parseFloat(newEventPrice);

      await addDoc(eventsCollectionRef, {
        eventName: newEventName,
        city: newEventCity,
        eventType: newEventType,
        venue: newEventVenue,
        startTime: startTimeTimestamp,
        endTime: newEventEndTime,
        price: priceAsNumber,
        location: newEventLocation, 
        link: newEventLink,
        userId: auth?.currentUser?.uid,
      });
      getEventList();
      setIsSubmitted(true);
    } catch (err) {
      console.error("newEventLocation is undefined or empty.");
    }
  };

  const navigate = useNavigate();

  // const updateEventLocation = (location) => {
  //   setNewEventLocation(location);
  // };

  const newEventCityOptions = [
    "Corvetto/ Rogoredo",
    "Precotto/ Turro",
    "Pasteur/ Rovereto",
    "Ripamonti/ Vigentino",
    "Citta Studi/ Susa",
    "Solari/ Washington",
    "San Siro/ Trenno",
    "Porta Romana/ Cadore/ Montenero",
    "Porta Venezia/ Indipendenza",
    "Fiera/ Sempione/ City Life/ Portello",
    "Navigli",
    "Garibaldi/ Moscova/ Porta Nuova",
    "Quadronno/ Palestro/ Guastalla",
    "Genova/ Ticinese",
    "Arco della Pace/ Arena/ Pagano",
    "Centrale/ Repubblica",
    "Bicocca/ Niguarda",
    "Cenisio/ Sarpi/ Isola",
    "Affori/ Bovisa",
    "Viale Certosa/ Cascina Merlata",
    "Bisceglie/ Baggio/ Olmi",
    "Bande Nere/ Inganni",
    "Forlanini",
    "Famagosta/ Barona",
    "Maggiolina/ Istria",
    "Abbiategrasso/ Chiesa Rossa",
    "Udine/ Lambrate",
    "Porta Vittoria/ Lodi",
    "Ponte Lambro/ Santa Giulia",
    "Cimiano/ Crescenzago/ Adriano",
    "Napoli/ Soderni",
    "Centro",
  ];

  const newEventTypeOptions = [
    "Music",
    "Market",
    "Art",
    "Sport",
    "Theatre",
    "Community",
    "Workout",
    "Talks",
    "Aperitivo",
  ];

  const popupStyle = {
    position: "fixed",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#ffb3ff",
    color: "black",
    border: "1px solid black",
    borderRadius: "5px",
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.2)",
    zIndex: 9999,
    textAlign: "left",
    overflow: "hidden",
    justifyContent: "space-between",
    display: "block"
  };

  const myevents = async () => {
    try {
      navigate('/myevents');
    } catch (err) {
      console.error(err);
    }
  };

  /////////////////////////
  /////places stuff///////////
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    //apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    callbackName: "Initmap",
    requestOptions: {
      componentRestrictions: { country: "IT" },
    },
  });
  
    const ref = useOnclickOutside(() => {
      // When user clicks outside of the component, we can dismiss
      // the searched suggestions by calling this method
      clearSuggestions();
    });
  
    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
    };
  
    const handleSelect = (val) => {
      setValue(val, false);
      setNewEventLocation(val); // Update newEventLocation state with the selected address
      clearSuggestions();
    };
   
    
   

  return (
    <div className="About about-section">
      <div style={{ width: "80%", textAlign: "center", marginLeft: "10%" , marginRight: "10%" }}>
        <div
          className="side-by-side"
          style={{
            color: "white",
            textAlign: "right",
            backgroundColor: "black",
          }}
        >
          {" "}
          What is the AWESOME event called?{" "}
        </div>
        <input
          placeholder="Event Name...max 100 chars"
          onChange={(e) => setNewEventName(e.target.value)}
          className="event-input-field side-by-side"
          maxLength="100"
          required
        />
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Which LOCAL zone is the venue?{" "}
        </div>
        <select
          value={newEventCity}
          onChange={(e) => setNewEventCity(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select a zone</option>
          {newEventCityOptions.map((zone, index) => (
            <option key={index} value={zone}>
              {zone}
            </option>
          ))}
        </select>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          And what type of SHINDIG is this?{" "}
        </div>
        <select
          value={newEventType}
          onChange={(e) => setNewEventType(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select a type</option>
          {newEventTypeOptions.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          And the SUPER venue name?{" "}
        </div>
        <input
          placeholder="Venue..."
          onChange={(e) => setNewEventVenue(e.target.value)}
          className="event-input-field side-by-side"
          maxLength="40"
          required
        />
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          And KICK-OFF time is when exactly?{" "}
        </div>
        <input
          type="datetime-local"
          placeholder="Start Time..."
          onChange={(e) => setNewEventStartTime(e.target.value)}
          className="event-input-field side-by-side"
          required
        />
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          All GOOD THINGS come to an end!{" "}
        </div>
        <input
          type="datetime-local"
          placeholder="End Time..."
          onChange={(e) =>
            setNewEventEndTime(Timestamp.fromDate(new Date(e.target.value)))
          }
          className="event-input-field side-by-side"
          required
        />
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          How much is it to get in? Put 0 if free :){" "}
        </div>
        <input
          placeholder="Price..."
          onChange={(e) => setNewEventPrice(e.target.value)}
          className="event-input-field side-by-side"
          required
        />
        <label id="demo"
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Let us map it for you{" "}
        </label>
       
        
        
      <Combobox onSelect={handleSelect} aria-labelledby="demo">
        <ComboboxInput value={value} onChange={handleInput} disabled={!ready}
          className="event-input-field side-by-side"
          placeholder="Search an address"
        />
        <ComboboxPopover className = "combobox-popover-class"
          
        >
          <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description}/>
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
   


        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Where can we find more info (insert a web url)?{" "}
        </div>
        <input
          placeholder="Link..."
          onChange={(e) => setNewEventLink(e.target.value)}
          className="event-input-field side-by-side"
          required
        />
        <button
          onClick={onSubmitEvent}
          style={{
            color: "white",
            margin: "20px",
            border: "1px solid white",
            borderRadius: "5px",
            backgroundColor: "black",
            alignItems: "center",
            cursor: "pointer"
          }}
        >
          Submit Event
        </button>
        <button onClick={myevents} style={{color: "white",
            margin: "20px",
            border: "1px solid white",
            borderRadius: "5px",
            backgroundColor: "black",
            alignItems: "center",
            cursor: "pointer"}}> 
      My Events </button>
        {/* Modal for Success Message and Options */}
        {isSubmitted && (
          <div className="modal">
            <div className="modal-content" style={popupStyle}>
              <p>Great! Your event was submitted.</p>
              <button
                onClick={() => {
                  window.location.reload();
                  navigate("/create");
                }}
              >
                Submit Another
              </button>
              <button onClick={() => navigate("/myevents")}>Show My Events</button>
              <button onClick={() => navigate("/home")}>
                Show Local Events
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function loadScript(url) {
  // Check if the script is already included
  if (!window.googleMapsScriptLoaded) {
      var index = window.document.getElementsByTagName("script")[0];
      var script = window.document.createElement("script");
      script.src = url;
      script.async = true;
      script.defer = true;
      index.parentNode.insertBefore(script, index);

      // Set a flag to indicate that the script has been loaded
      window.googleMapsScriptLoaded = true;
  }
}


;


