import { auth, googleProvider } from "../config/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendSignInLinkToEmail,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useState } from "react";


function SignUp  () {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const auth = getAuth();
  sendEmailVerification(auth.currentUser)
    .then(() => {
      // Email verification sent!
      // ...
    });

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      // Create a new user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Get the user object
      const user = userCredential.user;

      // Update the user's display name with first and last names
      // await user.updateProfile({
      //   displayName: `${firstName} ${lastName}`,
      // });

    // If signup is successful, set isSuccess to true and set the success message
    setIsSuccess(true);
    setSuccessMessage('Signup successful! Please check your email for verification.');
    setErrorMessage(''); // Clear any previous error message

      // Clear the form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
    } catch (error) {
      setIsSuccess(false);/* change this to be false*/
    setErrorMessage(error.message);
    setSuccessMessage('');
    }
  };

  const containerStyle = {
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //minHeight: '100vh', 
    
  };

  const formStyle = {
    padding: '10px',
    border: '1px solid white',
    borderRadius: '5px',
    width: '400px',
    color: "grey",
    margin: '20px'
  };

  const inputStyle = {
    width: '100%', // Input boxes 100% width within the form
    height: '2.5rem', // 1.5 times higher input boxes
    marginBottom: '20px', // Space between rows x2 larger
    borderRadius: '5px', // Rounded edges for inputs
    fontFamily: 'sans-serif',
    paddingLeft: '10px',
  };

  const messageStyle = {
    background: 'white',
    color: 'black',
    paddingLeft: '10px',
    paddingRight: '10px',
    minHeight: '10vh',
    backgroundColor: '#ffb3ff',
    color: 'black',
    fontSize: '20px',
    lineHeight: '40px',
    borderRadius: '5px',
    textAlign: 'center',
    fontWeight: 'bold',
    border: '2px solid white',
  }

  const messageContainerStyle = {
    fontfamily: 'sans-serif',
    fontsize: '50px',
    background: 'black',
    color: 'black',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    zIndex: 9999,
    textAlign: 'left',
    };
  

  return (
    <div style={containerStyle}>
    <div style={formStyle}>
      <h2 style={{paddingBottom: "25px"}}>Sign Up</h2>
      <form onSubmit={handleSignUp}>
        <div>
          <label>First Name:</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <button type="submit" style={{ background: 'none', color: 'white', border: '1px solid #ffb3ff', 
          borderRadius: '5px', padding: '10px 20px', cursor: 'pointer', textAlign: 'center' }}>Let's Go</button>
          
          
          <div style={messageContainerStyle}>
      {isSuccess && (<div style={messageStyle}>{successMessage}
      {errorMessage && <div style={messageStyle}>{errorMessage}</div>}
        </div>
      )}
        </div>

        </div>
      </form>
    </div>
    </div>
  );
};

export default SignUp;