///imports
import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { Auth } from "./components/auth";
import { db, auth, storage } from "./config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc, 
} from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import Navbar from './components/Navbar';
import Home from './pages/home';
import Initmap from './pages/about';
import Blogs from './pages/blogs';
import SignUp from './pages/signup';
import Contact from './pages/contact';
import LogIn from './pages/login';
import Map from './pages/mapview';
import Layout from "./Layout"; 
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate} from "react-router-dom";
import pluto from './pluto3.png';
import stars from './stars.png';
import { useLoadScript } from '@react-google-maps/api';
import "./App.css";
import DisclaimerModal from './DisclaimerModal';

function App() {
 
  const headerStyles = {
    width: '100%',
    backgroundColor: 'black',
    color: 'white',
    padding: '10px', 
    fontFamily: "'Monoton', sans-serif",
    textAlign: 'center',
    wordSpacing: '6px',
    fontSize: '35px',
    animation: 'flash 3s infinite'
  };
  
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    // Check if the disclaimer has been accepted in localStorage
    const isDisclaimerAccepted = localStorage.getItem('disclaimerAccepted') === 'true';
    setDisclaimerAccepted(isDisclaimerAccepted);
  }, []);

  const handleAcceptDisclaimer = () => {
    localStorage.setItem('disclaimerAccepted', 'true'); // Store the acceptance in localStorage
    setDisclaimerAccepted(true);
  };

  const AuthContext = createContext();

  const useAuth = () => {
  return useContext(AuthContext);
};

  const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

  const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/login" />;
};

  return (
    <div>
      <Router>
      <AuthProvider>
      {!disclaimerAccepted && <DisclaimerModal onAccept={handleAcceptDisclaimer} />}
        <Navbar /> {/* Render the Navbar outside of RouteContentWithImage */}
        <Routes>
          <Route
            exact
            path="/"
            element={
              <RouteContentWithImage>
                <Layout />
              </RouteContentWithImage>
            }
          />
          <Route path="/home" element={<Home />} />
          <Route path="/create" element={<PrivateRoute><Initmap /></PrivateRoute>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/myevents" element={<Blogs />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/mapview" element={<Map />} />
        </Routes>
        </AuthProvider>
      </Router>
      <div style={{ color: 'black', background: 'rgb(225, 141, 23)', width: '100%', display: 'inline-block', 
       textAlign: 'center',  padding: '20px' , borderColor: 'rgb(225, 141, 23)', fontfamily: '"Tomorrow", sans-serif' 
      }}>
         Email: luna.parque.events@gmail.com </div>
    </div>
  );
}

// Custom component for conditionally rendering content with an image
function RouteContentWithImage() {
  // Get the current route pathname
  const pathname = window.location.pathname;

  // Conditionally render the image
  const renderImage = pathname === '/';

  const navigate = useNavigate();
  
    const goToMusicList = () => {
      navigate('/home?filter=Music');
    };
  
    const goToMarketsList = () => {
      navigate('/home?filter=Community');
    };

    const goToSportList = () => {
      navigate('/home?filter=Sport');
    };

    const goToArtList = () => {
      navigate('/home?filter=Art');
    };

    const goToTheatreList = () => {
      navigate('/home?filter=Theatre');
    };

    const goToWorkoutList = () => {
      navigate('/home?filter=Workout');
    };

    const goToAperitivoList = () => {
      navigate('/home?filter=Aperitivo');
    };

    const goToTalksList = () => {
      navigate('/home?filter=Talks');
    };

  return (
    <div style={{ position: 'relative', textAlign: 'center', border: 'none' }}>
      <Slideshow className = 'general-css'/>
      <div style={{ color: 'black', background: 'white', width: '100%', display: 'inline-block', 
       textAlign: 'left',  paddingLeft: '20px', paddingTop: '5px', fontFamily: '"Tomorrow", sans-serif', fontSize: '14px'
      }}>
         Cerca eventi per tipo </div>
  <div style={{ paddingTop: '20px', paddingBottom: '20px', border: 'none', color: 'black', background: 'white', width: '100%', display: 'inline-block', paddingBottom: '20px',fontFamily: 'sans-serif', fontweight: 'bold'  }}>
    <div className="image-container">
      <div className="image-item" onClick={goToMusicList} style={{ cursor: 'pointer' }}>
        <img src="music10.png" alt=" " />
        <p className="type-fonts" >MUSIC</p>
      </div>
      <div className="image-item" onClick={goToMarketsList}>
        <img src="community7.png" alt=" " />
        <p className="type-fonts">MARKETS</p>
      </div>
      <div className="image-item" onClick={goToArtList} style={{ cursor: 'pointer' }}>
        <img src="artrev2.png" alt=" " />
        <p className="type-fonts"> ART</p>
      </div>
      <div className="image-item" onClick={goToTheatreList} style={{ cursor: 'pointer' }}>
        <img src="theatre2.png" alt=" " />
        <p className="type-fonts"> THEATRE</p>
      </div>
      <div className="image-item" onClick={goToSportList} style={{ cursor: 'pointer' }}>
        <img src="sport2.png" alt=" " />
        <p className="type-fonts">SPORT</p>
      </div>
      <div className="image-item" onClick={goToWorkoutList} style={{ cursor: 'pointer' }}>
        <img src="workout1.png" alt=" " />
        <p className="type-fonts">WORKOUT</p>
      </div>
      <div className="image-item" onClick={goToAperitivoList} style={{ cursor: 'pointer' }}>
        <img src="apero1.png" alt=" " />
        <p className="type-fonts">APERITIVO</p>
      </div>
      <div className="image-item" onClick={goToTalksList} style={{ cursor: 'pointer' }}>
        <img src="talks1.png" alt=" " />
        <p className="type-fonts">TALKS</p>
      </div>
    </div>
  </div>
  
    {renderImage && 
    <img src={pluto} alt="pluto" 
      style={{
        width: '100%',
        height: 'auto',
        margin: '0 auto', 
        display: 'block', 
        display: 'inline-block',
        position: 'relative',
        background: 'rgb(225, 141, 23)',
        borderTop: 'none',
        borderBottom: 'none',
      }}
      />
    }
    <div style={{background : 'rgb(225, 141, 23)'}}>
  <button
            type="button"
            onClick={() => navigate("/login")}
            className = "login-button"
          >
            Login | User Portal {" "}
          </button>
          <button
            type="button"
            onClick={() => navigate("/signup")}
            className = "login-button"
          >
            SignUp {" "}
          </button>        
          </div>
    </div>
  );
}

const Slideshow = () => {
  const texts = [
    'La mattina 🕘, il pommerigio 🕓 e la serata 🕙 a Milano ',
    'Ecco sono tutti gli eventi straordinari da fare a Milano 🎭 🎪 💃🏽',
    '100% Gratuito per creare e elencare eventi 🆓',
    'La tua privacy è importante per noi. Non tracciamo i cookie 🍪',
    '100% Made in Italy 🇮🇹 e 100% fatto con il cuore ❤️',
    'Clicca su qualsiasi categoria per iniziare ⬇️',
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current text index and wrap around if necessary
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000); // 10000 milliseconds (10 seconds)

    return () => {
      clearInterval(interval); // Cleanup the interval when the component unmounts
    };
  }, []);

  return (
    <div>
      {/* Display the current text */}
      <div className="text-loop"
       
      >
        {texts[currentTextIndex]}
      </div>
    </div>
  );
};

export default App;

