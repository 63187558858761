//// this is the front page
// export default Navbar;
import "./index.css";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logoImage from "./mapmarkerlogo.png";
import { useNavigate, Navigate } from "react-router-dom";
import { analytics } from "../../config/firebase.js";
import pluto from '..//../pluto3.png';

const Nav = styled.nav`
  background-color: rgb(225, 141, 23);
  color: #ffb3ff;
  display: flex;
  flex-direction: column; /* Display menu items vertically */
  align-items: center;
  justify-content: center;
  position: sticky;
  z-index: 100; 
  top: 0;
`;

const NavMenu = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%; /* Make the menu items take full width */
  text-align: center; /* Center align text */
  display: ${({ isOpen }) =>
    isOpen ? "block" : "none"}; /* Toggle visibility */
  margin-bottom: 30px;
`;

const NavItem = styled.li`
  margin-bottom: 3px; /* Add some space between menu items */
`;

const MenuToggle = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const Bar = styled.div`
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const navigate = useNavigate();

  const handleLogoClick = () => {
    // Log the event with Firebase Analytics
    analytics.logEvent('logo_click');
  };

  return (
    <Nav>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => navigate("/mapview")}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer", 
            }}
          >
            <img
              onClick={handleLogoClick}
              src={logoImage}
              alt="Logo"
              style={{ display: "flex", alignItems: "center" }}
              className="pluto-pic"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate("/");
              }}
              className="headerStyles"
            >
              {" "}
              LUNAPARQUE{" "}
            </button>
          </div>
        </div>
        {/*<MenuToggle onClick={toggleMenu}>*/}
        <MenuToggle onClick={() => navigate("/home")}>
          {/* <Bar></Bar>
          <Bar></Bar>
          <Bar></Bar> */}
           <img src={pluto} alt="pluto" 
     className="pluto-pic"
      />
        </MenuToggle>
      </div>
      <NavMenu isOpen={isMenuOpen} className="menu-font-size">
        <NavItem onClick={closeMenu}>
          <NavLink to="/">About</NavLink>
        </NavItem>
        <NavItem onClick={closeMenu}>
          <NavLink to="/home">PlayList</NavLink>
        </NavItem>
        <NavItem onClick={closeMenu}>
          <NavLink to="/mapview">Mapview</NavLink>
        </NavItem>
        <NavItem onClick={closeMenu}>
          {/* <NavLink to="/create" >
            Create
          </NavLink> */}
        </NavItem>
        <NavItem onClick={closeMenu}>
          <NavLink to="/login">Log In | User Portal</NavLink>
        </NavItem>
        <NavItem onClick={closeMenu}>
          {/* <NavLink to="/myevents" >
            My Events
          </NavLink> */}
        </NavItem>
        <NavItem onClick={closeMenu}>
          <NavLink to="/signup">SignUp</NavLink>
        </NavItem>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
