///imports - this is the private / myevents page
import React, { useEffect, useState, useRef } from "react";
import "./home.css";
import { db, auth, storage } from "../config/firebase";
import {
  query,
  where,
  getDocs,
  collection,
  deleteDoc,
  doc,
} from "firebase/firestore";
import Layout from "../Layout";
import { eventsCollectionRef } from './home';

function Blogs() {
  const [eventList, setEventList] = useState([]);
  const textContainerRef = useRef(null);
  const [newEventStartTime, setNewEventStartTime] = useState(null);

  // Function to format date and time
  const formatDateTime = (dateTime) => {
    const options = {
      month: "short",
      day: "2-digit",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return dateTime.toLocaleDateString("en-US", options);
  };

  //const eventsCollectionRef = collection(db, "events");
  //const user = auth.currentUser; // Get the authenticated user

  const deleteEvent = async (eventId) => {
    try {
      const eventDoc = doc(db, "events", eventId);
      await deleteDoc(eventDoc);
      // Refresh the event list after deletion
      getEventList();
    } catch (error) {
      console.error(error);
    }
  };

  const getEventList = async () => { 
    try {
      const user = auth.currentUser;
      console.log();
      if (user) {
        const data = await getDocs(
          query(eventsCollectionRef, where("userId", "==", user.uid))
        );
  
        const currentDate = new Date();
  
        // Filter events to include only those in the next 30 days
        const filteredData = data.docs
          .map((doc) => ({
            ...doc.data(),
            startTime1: new Date(
              doc.data().startTime.seconds * 1000 +
              doc.data().startTime.nanoseconds / 1000000
            ),
            id: doc.id,
          }))
          .filter((event) => {
            const eventDate = event.startTime1;
            const thirtyDaysFromNow = new Date();
            thirtyDaysFromNow.setDate(currentDate.getDate() + 364);
            return eventDate >= currentDate && eventDate <= thirtyDaysFromNow;
          });
  
        setEventList(filteredData);
      } else {
        console.log("No user is logged in.");
      }
    } catch (err) {
      console.error(err);
    }
  };
  

  useEffect(() => {
    getEventList();

    // Fetch additional event details (if necessary)
    const fetchEventDetails = async () => {
      try {
        // Fetch documents from the Firestore collection
        const querySnapshot = await getDocs(eventsCollectionRef);
  
        // Iterate through each document in the snapshot
        querySnapshot.forEach((doc) => {
          // Check if the document exists
          if (doc.exists()) {
            // Get the start time from the document data
            const startTime = doc.data().startTime;
  
            // Convert the Firestore timestamp to a JavaScript Date object
            setNewEventStartTime(
              new Date(startTime.seconds * 1000 + startTime.nanoseconds / 1000000)
            );
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    // Call the fetchEventDetails function
    fetchEventDetails();
  
  }); // Add 'filter' as a dependency

  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const handleEventTypeClick = (eventType) => {
    // Toggle the selected event type
    setSelectedEventTypes((prevSelected) => {
      if (prevSelected.includes(eventType)) {
        return prevSelected.filter((type) => type !== eventType);
      } else {
        return [...prevSelected, eventType];
      }
    });
  };

  const filteredEventList = eventList.filter((event) => {
    // If no event types are selected, show all events
    if (selectedEventTypes.length === 0) {
      return true;
    }
    // Check if the event type is in the selectedEventTypes
    return selectedEventTypes.includes(event.eventType);
  });

  // Sort the filteredEventList by startTime in ascending order
  filteredEventList.sort((eventA, eventB) => {
    // Convert startTime to JavaScript Date objects
    const startTimeA = new Date(eventA.startTime.toMillis());
    const startTimeB = new Date(eventB.startTime.toMillis());

    // Compare the start times
    if (startTimeA < startTimeB) {
      return -1; // eventA comes before eventB
    }
    if (startTimeA > startTimeB) {
      return 1; // eventA comes after eventB
    }
    return 0; // start times are equal
  });

  return (
    <div className="home-styles">
      <div>
        <div className="event-header sticky-header">
          <div className="event-container-home bg-black text-base/4 font-sans font-semibold">
            <div className="event-column-home event-type">
              <p className="event-info-home"> </p>
            </div>

            <div className="event-column-home event-name-home">
              <p className="event-info-home">What </p>
            </div>
            <div className="event-column-home event-city">
              <p className="event-info-home">Where</p>
            </div>
            <div className="event-column-home event-when">
              <p className="event-info-home">When</p>
            </div>
            <div className="event-column-home event-venue">
              <p className="event-info-home">Venue</p>
            </div>
            <div className="event-column-home">
              <p className="event-info-home">Price</p>
            </div>
            <div className="event-column-home">
              <p className="event-info-home">Delete?</p>
            </div>
          </div>
        </div>

        {filteredEventList.map((event) => (
          <div
            key={event.id}
            className="event-container-home bg-black text-xs font-sans"
          >
            <div className="event-column-home event-type">
              {event.eventType === "Music" && (
                <img
                  src="music10.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Music")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("music") ? "selected" : ""
                  }`}
                />
              )}
              {event.eventType === "Community" && (
                <img
                  src="community7.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Community")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("Community") ? "selected" : ""
                  }`}
                />
              )}
              {event.eventType === "Art" && (
                <img
                  src="artrev2.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Art")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("Art") ? "selected" : ""
                  }`}
                />
              )}
              {event.eventType === "Theatre" && (
                <img
                  src="theatre2.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Theatre")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("Theatre") ? "selected" : ""
                  }`}
                />
              )}
              {event.eventType === "Sport" && (
                <img
                  src="sport2.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Sport")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("Sport") ? "selected" : ""
                  }`}
                />
              )}
              {event.eventType === "Workout" && (
                <img
                  src="workout1.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Workout")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("Workout") ? "selected" : ""
                  }`}
                />
              )}
              {event.eventType === "Aperitivo" && (
                <img
                  src="apero1.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Aperitivo")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("Aperitivo") ? "selected" : ""
                  }`}
                />
              )}
              {event.eventType === "Talks" && (
                <img
                  src="talks1.png"
                  alt=" "
                  onClick={() => handleEventTypeClick("Talks")}
                  className={`event-type-image ${
                    selectedEventTypes.includes("Talks") ? "selected" : ""
                  }`}
                />
              )}
            </div>
            <div className="event-column-home event-name-home">
              <a
                href={event.link}
                target="_blank"
                rel="noopener noreferrer"
                className="event-info-home "
              >
                {event.eventName}
              </a>
            </div>
            <div className="event-column-home event-city bg-black">
              <p className="event-info-home">{event.city}</p>
            </div>
            <div className="event-column-home event-when">
              <p className="event-info-home">
                {" "}
                {event.startTime1
                  ? formatDateTime(event.startTime1)
                  : "Loading..."}
              </p>
            </div>

            <div className="event-column-home event-venue">
              <p className="event-info-home">{event.venue}</p>
            </div>
            <div className="event-column-home">
              <p className="event-info-home">{event.price}</p>
            </div>
            <div className="event-column-home">
            <button onClick={() => deleteEvent(event.id)} style={{fontSize: "8px"}}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blogs;
