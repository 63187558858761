import { useState, useMemo, useEffect, useRef } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "./mapviewstyles.css";
import { db} from "../config/firebase";
import { collection, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from 'react-router-dom';


export default function Places() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"]
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(() => ({ lat: 45.45, lng: 9.19 }), []);
  const [selected, setSelected] = useState(null);
  const [eventMarkers, setEventMarkers] = useState([]);
  const mapRef = useRef(null); // Create a ref for the GoogleMap component
  const [circleMarker, setCircleMarker] = useState(null); // State to store the circle marker style
  const [selectedEventType, setSelectedEventType] = useState('all');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const venueFilter = queryParams.get('venue');
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if google object is available
    if (mapRef.current && window.google) {
      // Define the circle marker style
      const circleMarkerStyle = {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 6,
        fillColor: "#FF0000",
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#FFFFFF",
      };
      setCircleMarker(circleMarkerStyle);
    }
    
    // Fetch events from Firestore
    const fetchEvents = async () => {
      // Fetch events
      const snapshot = await getDocs(collection(db, "events"));
    
      // Convert events and filter
      const eventsData = snapshot.docs
      .map(doc => {
        const eventData = doc.data();
        const eventDate = eventData.startTime.toDate();
        return { ...eventData, id: doc.id, eventDate };
      })
      .filter(event => {
        // Apply the selected month and event type filters
        const eventMonth = event.eventDate.getMonth();
        const monthAndTypeFilter = (eventMonth === selectedMonth ||
                                    eventMonth === (selectedMonth + 1) % 12) &&
                                   (selectedEventType === 'all' || event.eventType === selectedEventType)
                                   ;
        
        // Apply the venue filter, if venueFilter is set
        const venueFilterCheck = venueFilter ? event.venue === venueFilter : true;

        // const eventDay = event.eventDate.getDate().toString(); // Extracting the day as a string
        // const isSelectedDay = selectedDate ? eventDay === selectedDate : true;

        return monthAndTypeFilter && venueFilterCheck;
      });

  // Filter events based on selectedEventType
  const filteredEventsData = selectedEventType === 'all' 
    ? eventsData 
    : eventsData.filter(event => event.eventType === selectedEventType);
  
  // Map filtered events to markers
  const markers = await Promise.all(filteredEventsData.map(async (event) => {
    try {
      const results = await getGeocode({ address: event.location });
      const { lat, lng } = getLatLng(results[0]);

      // Get color based on eventType
      const markerColor = getMarkerColor(event.eventType);

      return {
        id: event.id,
        position: { lat, lng },
        color: markerColor, // Store the color in the marker object
        eventName: event.eventName,
        venue: event.venue,
        startTime: event.startTime,
        endTime: event.endTime,
        price: event.price,
        link: event.link,
      };
    } catch (error) {
      console.error("Error getting event location:", error);
      return null;
    }
  }));

  setEventMarkers(markers.filter(marker => marker !== null));
};

fetchEvents();

  }, [selectedEventType, mapRef, venueFilter]);
  
  const mapStyles = [
    {
      featureType: "poi", // Points of Interest
      elementType: "labels", // Labels of POIs
      stylers: [{ visibility: "off" }] // Turn off visibility
    },
    {
      featureType: "landscape.natural.landcover", // Target natural landcover (often includes parks)
      elementType: "geometry",
      stylers: [{ visibility: "off" }] 
    },
    {
      featureType: "landscape.natural.terrain", // Target natural terrain (also includes park areas)
      elementType: "geometry",
      stylers: [{ visibility: "off" }] 
    },
    {
      featureType: "transit.station.bus", // Target bus stations
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit.station.rail", // Target rail stations (includes subway and train stations)
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit.line", // Target transit lines
      elementType: "geometry",
      stylers: [{ visibility: "off" }]
    }
  ];

  const eventTypeColors = {
    Art: "#ff00ff", 
    Community: "#00ff00", 
    Workout: "#00ffff", 
    Sport: "#ffff00", 
    Aperitivo: "#ff0000", 
    Talks: "#0000ff", 
    Theatre: "#ff7f00", 
    Market: "#7f00ff", 
    Music: "#000000",
  };
  
  function getMarkerColor(eventType) {
    return eventTypeColors[eventType] || "#FFFFFF"; // Default color if eventType not found
  }
  
  

  const handleEventTypeChange = (event) => {
    setSelectedEventType(event.target.value);
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleMarkerHover = (markerId) => {
    setActiveMarker(markerId);
  };

  const handleMarkerHoverExit = () => {
    setActiveMarker(null);
  };

  function formatDate(firestoreTimestamp) {
    if (!firestoreTimestamp) return '';
  
    // Convert Firestore timestamp to JavaScript Date object
    const date = firestoreTimestamp.toDate();
  
    // Separate formatting for date and time
    const dateOptions = { month: 'short', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
  
    // Combine formatted date and time
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
  
    return `${formattedDate}, ${formattedTime}`;
  }

  const handleMarkerClick = (link) => {
    if (link) {
      // Check if the link starts with 'http://' or 'https://'
      const hasProtocol = link.startsWith('http://') || link.startsWith('https://');
      const fullLink = hasProtocol ? link : `http://${link}`;
      window.open(fullLink, '_blank'); // Opens the link in a new window/tab
    }
  };
  
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Generate month options
  const monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
  const currentMonth = new Date().getMonth();

  const removeAllFilters = () => {
    // Reset selected event type to default
    setSelectedEventType('all');

    // Navigate to the map page without any query parameters
    navigate('/mapview');
  };

  const mapOptions = {
    gestureHandling: 'greedy',
    // ... any other map options you want to include
  };

  ////// date picker */
  
  //   const [selectedDate, setSelectedDate] = useState(null);
  //   const [nextSevenDays, setNextSevenDays] = useState([]);

  //   useEffect(() => {
  //       const dates = [];
  //       for (let i = 0; i < 28; i++) {
  //           const date = new Date();
  //           date.setDate(date.getDate() + i);
  //           dates.push(date);
  //       }
  //       setNextSevenDays(dates);
  //   }, []);

  //   const formatDate1 = (date) => {
  //     return `${date.getDate()}`;
  // };  

  //   /*return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;*/

  //   const handleDateSelect = (date) => {
  //     setSelectedDate(date.getDate().toString());
  // };
  
  

  return (
    <>
      {/* <div className="date-picker-container">
            {nextSevenDays.map((date, index) => (
                <button 
                    key={index} 
                    onClick={() => handleDateSelect(date)}
                    className={selectedDate === date.getDate().toString() ? 'selected' : ''}
                >
                    {formatDate1(date)}
                </button>
            ))}
        </div> */}
      <div className="filter-container">
      <div className="text-box-above-header2">
      <button onClick={removeAllFilters} className='all-event-filter'>Mostra tutti</button>
        </div>
        <label htmlFor="eventTypeFilter" className="filter-label">Sceglie Tipo:</label>
        <select id="eventTypeFilter" className="event-type-select" value={selectedEventType} onChange={handleEventTypeChange}>
        {selectedEventType} onChange={handleEventTypeChange}
          <option value="all">Tutti</option>
          <option value="Sport">Sport</option>
          <option value="Art">Art</option>
          <option value="Community">Community</option>
          <option value="Workout">Workout</option>
          <option value="Aperitivo">Aperitivo</option>
          <option value="Talks">Talks</option>
          <option value="Theatre">Theatre</option>
          <option value="Market">Market</option>
          <option value="Music">Music</option>
        </select>

      </div>
      
      <GoogleMap
        ref={mapRef}
        zoom={12}
        center={center}
        mapContainerClassName="map-container"
        options={{ 
          ...mapOptions, 
          styles: mapStyles, 
          mapTypeControl: false, 
          streetViewControl: false 
        }} 
      >
        {selected && circleMarker && (
          <Marker position={selected} icon={circleMarker} />
        )}
        {eventMarkers.map(marker => (
          <Marker
            key={marker.id}
            position={marker.position}
            icon={{
              ...circleMarker,
              fillColor: marker.color,
              strokeColor: "#FFFFFF"
            }}
            onClick={() => handleMarkerClick(marker.link)}
            onMouseOver={() => handleMarkerHover(marker.id)}
            onMouseOut={handleMarkerHoverExit}
          >
            {activeMarker === marker.id && (
              <InfoWindow position={marker.position} onCloseClick={handleMarkerHoverExit}>
                <div className="info-window">
                  <h3 className="event-name">{marker.eventName}</h3>
                  <p>Venue: {marker.venue}</p>
                  {marker.startTime && <p>Start Time: {formatDate(marker.startTime)}</p>}
                  {marker.endTime && <p>End Time: {formatDate(marker.endTime)}</p>}
                  <p>price: €{marker.price}</p>
                  <p>Click the color dot to find out more!</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </>
  );
  
}

// const PlacesAutocomplete = ({ setSelected }) => {
//   const {
//     ready,
//     value,
//     setValue,
//     suggestions: { status, data },
//     clearSuggestions,
//   } = usePlacesAutocomplete({
//     requestOptions: {
//       componentRestrictions: { country: "IT" }, // Restrict to Italy
//     },
//   });

//   const handleSelect = async (address) => {
//     setValue(address, false);
//     clearSuggestions();

//     const results = await getGeocode({ address });
//     const { lat, lng } = await getLatLng(results[0]);
//     setSelected({ lat, lng });
//   };

//   return (
//     <Combobox onSelect={handleSelect}>
//       <ComboboxInput
//         value={value}
//         onChange={(e) => setValue(e.target.value)}
//         disabled={!ready}
//         className="combobox-input"
//         placeholder="Search an address"
//       />
//       <ComboboxPopover>
//         <ComboboxList>
//           {status === "OK" &&
//             data.map(({ place_id, description }) => (
//               <ComboboxOption key={place_id} value={description} />
//             ))}
//         </ComboboxList>
//       </ComboboxPopover>
//     </Combobox>
//   );
// }
;