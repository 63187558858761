import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyDwlfvEJJgeFrCrwmWdo6QfNREPDgEvF3Q",
  authDomain: "tickets-aside.firebaseapp.com",
  projectId: "tickets-aside",
  storageBucket: "tickets-aside.appspot.com",
  messagingSenderId: "913462477660",
  appId: "1:913462477660:web:d618e8ad3b765c43847799",
  measurementId: "G-T841HMMLFT"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const storage = getStorage(app);

export const analytics = getAnalytics(app);
