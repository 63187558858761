import React from 'react';

const Contact = () => {
	return (
		<div>
			<h1 style={{fontSize: '14px', padding: '20px'}}>
Event Viewer Disclaimer:
<br />
Welcome to LunaParque! Please be advised of the following as you browse and interact with event listings on our site:
<br />
<br />
- Accuracy of Event Listings: LunaParque is a platform for event organizers to share information about their events. While we strive for accuracy, we do not guarantee the correctness, completeness, or reliability of the information provided in event listings. Event details such as dates, times, locations, and descriptions are subject to change and are the responsibility of the event organizer.
<br />
- No Liability for Inaccuracies: LunaParque shall not be liable for any errors, inaccuracies, or misrepresentations found in event listings. We recommend verifying the event details directly with the event organizers or official sources before making any plans or decisions based on the information provided.
<br />
- Third-Party Content: Event listings may contain links to third-party websites or information provided by external sources. LunaParque has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services.
<br />
- Changes and Cancellations: Events are subject to change or cancellation by the organizers at any time. LunaParque is not responsible for any modifications, postponements, or cancellations of events and the consequences thereof.
<br />
- By using LunaParque, you acknowledge and agree that you bear all risks associated with the use of any content on the site and agree not to hold LunaParque liable for any claims related to incorrect event listings.
<br />
<br />
Thank you for visiting LunaParque, and we hope you find our platform helpful in discovering exciting events!
<br />
<br />
Event Lister Disclaimer: 
<br />
Welcome to LunaParque! Before you proceed with adding your event listings, please take a moment to read our disclaimer:
<br />
<br />
- Accuracy of Information: As the event organizer, you are responsible for ensuring that all information submitted in your event listings, including dates, times, locations, and descriptions, is accurate, complete, and up-to-date.
<br />
- Content Ownership and Permissions: You affirm that you have the right to use and share all content you post, including text, images, and any other media. This includes adhering to copyright laws and having necessary permissions for any third-party content.
<br />
- No Endorsement: Listing your event on our site does not imply any form of endorsement or partnership by LunaParque. All events are subject to review and approval based on our community standards and policies.
<br />
- User Conduct: We expect all event organizers to conduct themselves in a lawful and respectful manner. Events that promote hate, discrimination, or illegal activities will not be tolerated.
<br />
- Modification and Removal: LunaParque reserves the right to edit, modify, or remove any event listing at our discretion, particularly if it violates our terms of service, community standards, or applicable laws.
<br />
- Liability Limitation: LunaParque is not liable for any direct or indirect consequences that may result from the posting or cancellation of your event. Organizers are solely responsible for their events and any issues that arise from them.
<br />
- By submitting your event listing, you agree to these terms and acknowledge that LunaParque is not responsible for the content of individual event listings or the outcomes of these events.
<br />
<br />
Thank you for choosing LunaParque to promote your event!
				
			</h1>
		</div>
	);
};

export default Contact;
