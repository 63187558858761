/////this is the PlayList page
///imports
import React, { useEffect, useState, useRef } from "react";
import "./home.css";
import { db } from "../config/firebase";
import { getDocs, collection } from "firebase/firestore";
import Layout from "../Layout";
import { useLocation } from 'react-router-dom';

export const eventsCollectionRef = collection(db, "events");

function Home() {
  const [eventList, setEventList] = useState([]);
  const textContainerRef = useRef(null);
  const [newEventStartTime, setNewEventStartTime] = useState(null);
  const [currentTextIndex, setCurrentTextIndex] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = queryParams.get('filter');

  // Function to format date and time
  const formatDateTime = (dateTime) => {
    const options = {
      day: "2-digit",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return dateTime.toLocaleDateString("en-US", options);
  };

  

  const getEventList = async () => {
    try {
      const data = await getDocs(eventsCollectionRef);
      const currentDate = new Date();

      // Filter events to include only those in the next 30 days
      const filteredData = data.docs
        .map((doc) => ({
          ...doc.data(),
          startTime1: new Date(
            doc.data().startTime.seconds * 1000 +
              doc.data().startTime.nanoseconds / 1000000
          ),
          id: doc.id,
        }))
        .filter((event) => {
          const eventDate = event.startTime1;
          const thirtyDaysFromNow = new Date();
          thirtyDaysFromNow.setDate(currentDate.getDate() + 30);
          return eventDate >= currentDate && eventDate <= thirtyDaysFromNow;
        });

      setEventList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Fetch the event list
    getEventList();
  
    // Set the filter based on the URL parameter
    if (filter) {
      setSelectedEventTypes([filter]);
    }
  
    // Fetch additional event details (if necessary)
    const fetchEventDetails = async () => {
      try {
        // Fetch documents from the Firestore collection
        const querySnapshot = await getDocs(eventsCollectionRef);
  
        // Iterate through each document in the snapshot
        querySnapshot.forEach((doc) => {
          // Check if the document exists
          if (doc.exists()) {
            // Get the start time from the document data
            const startTime = doc.data().startTime;
  
            // Convert the Firestore timestamp to a JavaScript Date object
            setNewEventStartTime(
              new Date(startTime.seconds * 1000 + startTime.nanoseconds / 1000000)
            );
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    // Call the fetchEventDetails function
    fetchEventDetails();
  
  }, [filter]); // Add 'filter' as a dependency
  

  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const handleEventTypeClick = (eventType) => {
    // Toggle the selected event type
    setSelectedEventTypes((prevSelected) => {
      if (prevSelected.includes(eventType)) {
        return prevSelected.filter((type) => type !== eventType);
      } else {
        return [...prevSelected, eventType];
      }
    });
  };

  const filteredEventList = eventList.filter((event) => {
    // If no event types are selected, show all events
    if (selectedEventTypes.length === 0) {
      return true;
    }
    // Check if the event type is in the selectedEventTypes
    return selectedEventTypes.includes(event.eventType);
  });

  // Sort the filteredEventList by startTime in ascending order
filteredEventList.sort((eventA, eventB) => {
  // Convert startTime to JavaScript Date objects
  const startTimeA = new Date(eventA.startTime.toMillis());
  const startTimeB = new Date(eventB.startTime.toMillis());

  // Compare the start times
  if (startTimeA < startTimeB) {
    return -1; // eventA comes before eventB
  }
  if (startTimeA > startTimeB) {
    return 1; // eventA comes after eventB
  }
  return 0; // start times are equal
});

const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Month is 0-indexed (0 for January, 11 for December)
  const nextMonth = (currentMonth + 1) % 12; // Ensures it wraps around to 0 (January) after 11 (December)

  // Array of month names
  const monthNames = ["Gennaio", "Febraio", "Marzo", "Aprile", "Maggio", "Giugno",
    "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

  // Message to display
  const displayMessage = `Ecco gli eventi per i prossimi 30 giorni in ${monthNames[currentMonth]} e ${monthNames[nextMonth]}. Select ICONS to filter by TYPE`;

  // Function to handle removing the filter
  const removeAllFilters = () => {
    setSelectedEventTypes([]);
  };

  return (
    
      <div className="home-styles">
        <div>
        {/* Text Box with the dynamic message */}
        <div className="text-box-above-header"> 
        <div className= "listing-events">{displayMessage}
        <button onClick={removeAllFilters} className="all-event-filter" >Mostra tutti</button> </div>
        </div>
          <div className="event-header sticky-header">
            <div className="event-container-home bg-black text-base/4 font-sans font-semibold">
              <div className="event-column-home event-type">
                <p className="event-info-home">Type </p>
              </div>

              <div className="event-column-home event-name-home">
                <p className="event-info-home">What </p>
              </div>
              <div className="event-column-home event-city-home">
                <p className="event-info-home">Where</p>
              </div>
              <div className="event-column-home event-when">
                <p className="event-info-home">When</p>
              </div>
              <div className="event-column-home event-venue">
                <p className="event-info-home">Venue</p>
              </div>
              <div className="event-column-home">
                <p className="event-info-home">Price</p>
              </div>
            </div>
          </div>

          {filteredEventList.map((event) => (
            <div key={event.id} className="event-container-home bg-black font-sans">
              <div className="event-column-home event-type">
                {event.eventType === "Music" && (
                  <img
                    src="music10.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Music")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('music') ? 'selected' : ''
                    }`}
                  />
                )}
                {event.eventType === "Community" && (
                  <img
                    src="community7.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Community")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('Community') ? 'selected' : ''
                    }`}
                  />
                )}
                {event.eventType === "Art" && (
                  <img
                    src="artrev2.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Art")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('Art') ? 'selected' : ''
                    }`}
                  />
                )}
                {event.eventType === "Theatre" && (
                  <img
                    src="theatre2.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Theatre")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('Theatre') ? 'selected' : ''
                    }`}
                  />
                )}
                {event.eventType === "Sport" && (
                  <img
                    src="sport2.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Sport")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('Sport') ? 'selected' : ''
                    }`}
                  />
                )}
                {event.eventType === "Workout" && (
                  <img
                    src="workout1.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Workout")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('Workout') ? 'selected' : ''
                    }`}
                  />
                )}
                {event.eventType === "Aperitivo" && (
                  <img
                    src="apero1.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Aperitivo")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('Aperitivo') ? 'selected' : ''
                    }`}
                  />
                )}
                {event.eventType === "Talks" && (
                  <img
                    src="talks1.png"
                    alt=" "
                    onClick={() => handleEventTypeClick("Talks")}
                    className={`event-type-image ${
                      selectedEventTypes.includes('Talks') ? 'selected' : ''
                    }`}
                  />
                )}
              </div>
              <div className="event-column-home event-name-home truncate tooltip" style={{textDecoration: "underline"}}>
              <a
                href={typeof event.link === 'string' && event.link.startsWith('http') ? event.link : `https://${event.link}`}
                target="_blank"
                rel="noopener noreferrer"
                className="event-info-home"
                >
                {event.eventName.length > 80 ? ( 
                  <div className="marquee"><span>{event.eventName}</span></div>
                ) : (
                  event.eventName
                )}
              </a>
              </div>
              <div className="event-column-home event-city-home bg-black">
                <p className="event-info-home">{event.city}</p>
              </div>
              <div className="event-column-home event-when">
                <p className="event-info-home">
                  {" "}
                  {event.startTime1
                    ? formatDateTime(event.startTime1)
                    : "Loading..."}
                </p>
              </div>

              <div className="event-column-home event-venue" style={{textDecoration: "underline"}}>
              <a 
                href={`/mapview?venue=${encodeURIComponent(event.venue)}`} 
                rel="noopener noreferrer" 
                className="event-info-home"
              >
                {event.venue}
              </a>
              </div>
              <div className="event-column-home">
                <p className="event-info-home">{event.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
   
  );
}

export default Home;
