import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom if you're using React Router

const DisclaimerModal = ({ onAccept }) => {
  return (
    <div className="disclaimer-modal">
      <div className="modal-content">
        <h2>Disclaimer</h2>
        <p>Please read and accept our <Link to="/contact">disclaimer</Link> before continuing.</p>
        <button onClick={onAccept}>I Accept</button>
      </div>
    </div>
  );
};

export default DisclaimerModal;
