import { auth, googleProvider } from "../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ".//login.css";


function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/create");
    } catch (err) {
      console.error(err);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate("/create");
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };
  

  const myevents = async () => {
    try {
      navigate("/myevents");
    } catch (err) {
      console.error(err);
    }
  };

  const create = async () => {
    try {
      navigate("/create");
    } catch (err) {
      console.error(err);
    }
  };

  const resetPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset email sent!");
      } catch (err) {
        console.error(err);
        alert("Failed to send password reset email.");
      }
    } else {
      alert("Please enter your email address.");
    }
  };

  const containerStyle = {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
  };

  const formStyle = {
    padding: "10px",
    border: "1px solid white",
    borderRadius: "5px",
    width: "400px",
    color: "white",
    margin: "20px",
  };

  const inputStyle = {
    width: "100%", // Input boxes 100% width within the form
    height: "2.5rem", // 1.5 times higher input boxes
    marginBottom: "20px", // Space between rows x2 larger
    borderRadius: "5px", // Rounded edges for inputs
    fontFamily: "sans-serif",
    paddingLeft: "10px",
  };

  const buttonContainer = {
    display: "block",
    marginBottom: "10px",
    paddingLeft: "20px"
  };

  return (
    <div style={containerStyle}>
      <div style={formStyle}>
        <h1 style={{ paddingBottom: "25px", paddingLeft: "5px", textAlign: "center" }}>Login to list events </h1>
        <input
          style={inputStyle}
          placeholder="Email..."
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          style={inputStyle}
          placeholder="Password..."
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="button-container">
          <button
            type="button"
            onClick={signIn}
            className = "button-style"
          >
            Sign In
          </button>
          </div>
          <button
            type="button"
            onClick={signInWithGoogle}
            className = "button-style"
          >
            Sign In With Google
          </button>
          <button
            type="button"
            onClick={resetPassword}
            className = "button-style"
          >
            Reset Password
          </button>
          <button
            type="button"
            onClick={logout}
            className = "button-style"
          >
            Logout{" "}
          </button>
          <h1 style={{ paddingBottom: "25px", paddingLeft: "5px", textAlign: "center"  }}>Or go straight to create or view my events </h1>
          <button
            type="button"
            onClick={myevents}
            className = "button-style"
            
          >
            My Events{" "}
          </button>
          <button
            type="button"
            onClick={create}
            className = "button-style"
          >
            Create Event{" "}
          </button>
        
      </div>
    </div>
  );
}

export default LogIn;
